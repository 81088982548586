import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "../shared/index.css";

const router = createBrowserRouter([
  {
    path: "/",
    async lazy() {
      const { default: Component } = await import("../modernwallet/Root");
      return { Component };
    },
  },
  {
    path: "/scan",
    async lazy() {
      const { default: Component } = await import("../modernwallet/Scan");
      return { Component };
    },
  },
  {
    path: "/pay",
    async lazy() {
      const { default: Component } = await import("../modernwallet/Pay");
      return { Component };
    },
  },
]);

const appRoot = document.getElementById("app-root");

if (appRoot) {
  ReactDOM.createRoot(appRoot).render(
    <StrictMode>
      <RouterProvider router={router} />
    </StrictMode>,
  );
}
